import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Section } from "../../Core";
import image1 from './../../../assets/image/rebrand/png/b-youmesh.png'

const Speed = (props) => {
    return (
        <Section className="light-green-bg">
            <Container>
                <Row>
                    <Col sm={12} lg={6}>
                        <h2>
                            Get things<br />done faster
                        </h2>
                        <p className="mt-50 mb-50">
                            Get your business flying at 10,000Mbps.<br />Speak to one of our team on 0330 8181667.
                        </p>
                    </Col>
                    <Col sm={12} lg={6}>
                        <img src={image1} className="w-100 ratio-1 pb-5"/>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
};

export default Speed;
